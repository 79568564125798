




































import Vue, { PropType } from 'vue';
import { Feature } from '@/types';
import PlusIcon from '@/assets/icons/plus.svg?inline';
import FeatureLabel from '@/components/util/FeatureLabel.vue';

export default Vue.extend({
  name: 'layer-selection-popup',
  components: {
    PlusIcon,
    FeatureLabel,
  },
  props: {
    features: {
      type: Array as PropType<Feature[]>,
      required: true,
    },
    parentlessCommentsEnabled: { type: Boolean, required: true },
  },
  methods: {
    selectFeature(feature: Feature) {
      this.$emit('on-feature-select', feature);
    },
  },
});
