














import Vue, { PropType, VueConstructor } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { ContentBlockConfig, ContentBlockTypes, Feature } from '@/types';
import ValidatedAttributeInput from '@/components/side-bar/content/blocks/sub-components/ValidatedAttributeInput.vue';
import CustomerAddressInputBlock from '@/components/side-bar/content/blocks/CustomerAddressInputBlock.vue';
import CheckboxGroupInputBlock from '@/components/side-bar/content/blocks/CheckboxGroupInputBlock.vue';
import RichTextBlock from './blocks/RichTextBlock.vue';
import SingleLineTextInputBlock from './blocks/SingleLineTextInputBlock.vue';
import MultiLineInputBlock from './blocks/MultiLineInputBlock.vue';
import RadioButtonInputBlock from './blocks/RadioButtonInputBlock.vue';
import DropdownInputBlock from './blocks/DropdownInputBlock.vue';
import CheckboxInputBlock from './blocks/CheckboxInputBlock.vue';
import DividerBlock from './blocks/DividerBlock.vue';
import SpeedTestBlock from './blocks/speed-test-block/SpeedTestBlock.vue';
import EmailInputBlock from './blocks/EmailInputBlock.vue';
import FeatureInfoBlock from './blocks/feature-info-block/FeatureInfoBlock.vue';
import ParentSelectionInputBlock from './blocks/ParentSelectionInputBlock.vue';

export const BlockInputConfigMap: ReadonlyMap<ContentBlockTypes, VueConstructor> = new Map<
  ContentBlockTypes,
  VueConstructor
>([
  [ContentBlockTypes.RichText, RichTextBlock],
  [ContentBlockTypes.DividerBlock, DividerBlock],
  [ContentBlockTypes.SingleLineTextInput, SingleLineTextInputBlock],
  [ContentBlockTypes.MultiLineTextInput, MultiLineInputBlock],
  [ContentBlockTypes.RadioButtonInput, RadioButtonInputBlock],
  [ContentBlockTypes.CustomerAddressInput, CustomerAddressInputBlock],
  [ContentBlockTypes.DropdownInput, DropdownInputBlock],
  [ContentBlockTypes.CheckboxInput, CheckboxInputBlock],
  [ContentBlockTypes.CheckboxGroupInput, CheckboxGroupInputBlock],
  [ContentBlockTypes.SpeedTest, SpeedTestBlock],
  [ContentBlockTypes.EmailInput, EmailInputBlock],
  [ContentBlockTypes.FeatureInfo, FeatureInfoBlock],
  [ContentBlockTypes.ParentSelectionInput, ParentSelectionInputBlock],
]);

export default Vue.extend({
  name: 'sidebar-block',
  components: {
    RichTextBlock,
    SingleLineTextInputBlock,
    RadioButtonInputBlock,
    DropdownInputBlock,
    CheckboxInputBlock,
    MultiLineInputBlock,
    DividerBlock,
    SpeedTestBlock,
    CustomerAddressInputBlock,
    ValidatedAttributeInput,
    CheckboxGroupInputBlock,
    EmailInputBlock,
    FeatureInfoBlock,
    ParentSelectionInputBlock,
  },
  props: {
    blockConfig: { type: Object as PropType<ContentBlockConfig>, required: true },
    parentFeature: { type: Object as PropType<Feature>, required: false },
    featuresAtFocusPoint: { type: Array as PropType<Feature[]>, required: false },
    parentlessCommentsEnabled: { type: Boolean, required: true },
    disabled: { type: Boolean, required: false },
    disabledReason: { type: String, required: false },
  },
  computed: {
    ...mapState('sidebar', ['surveyData']),
    ...mapGetters('polygonIntersections', ['getMappedPolygonIntersectionConfig']),
    mappedConfig(): ContentBlockConfig {
      return this.getMappedPolygonIntersectionConfig(this.blockConfig);
    },
    blockComponent(): string | undefined {
      // VueConstructor has an undocumented API with a property called 'options' which contains the component name.
      // For more info, see: https://github.com/vuejs/vue-class-component/issues/337
      return (
        BlockInputConfigMap.get(this.mappedConfig.type) as VueConstructor & {
          options: { name: string };
        }
      )?.options.name;
    },
  },
});
