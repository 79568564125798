





































































import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import _pickBy from 'lodash.pickby';
import format from 'string-format';
import { Polygon, AllGeoJSON, Position } from '@turf/helpers';
import {
  createChildFeature,
  mapPolygonsToRedirectURL,
  upsertParentWithChild,
} from '@/util/features';
import {
  ContentBlockConfig,
  ContentBlockTypes,
  Feature,
  InputBlockConfig,
  isInputBlockConfig,
  ParentChildPair,
  RedirectionConfig,
  SubmissionConfig,
} from '@/types';
import turfCentroid from '@turf/centroid';
import { failureAlert } from '@/util/alert';
import { pointFromPosition } from '@/util/geom';
import { getMatchingIntersectionRelationshipPolygons } from '@/util/polygonIntersections';
import { PolygonIntersectionIdentifier } from '@/types/polygonIntersections';
import CloseButton from '@/components/util/CloseButton.vue';
import SidebarBlock from './SidebarBlock.vue';
import CommentFeed from './comments/CommentFeed.vue';

interface SidebarData {
  termsAreAccepted: boolean;
  submitting: boolean;
  chosenParentFeature: Feature | null;
}

export default Vue.extend({
  name: 'sidebar-content',
  components: { SidebarBlock, CommentFeed, CloseButton },
  data(): SidebarData {
    return {
      termsAreAccepted: false,
      submitting: false,
      chosenParentFeature: null,
    };
  },

  computed: {
    ...mapGetters('config', [
      'getViewAttributes',
      'isFeatureSelectionEnabled',
      'commentableLayerIds',
      'parentlessCommentsEnabled',
    ]),
    ...mapGetters('sidebar', ['getSubmissionRedirectUrl', 'getFormValidity']),
    ...mapGetters('comments', ['commentParentPairs']),
    ...mapState('polygonIntersections', [
      'polygonIntersectionRelationships',
      'isLoadingPolygonIntersectionRelationships',
    ]),
    ...mapState('sidebar', ['surveyData', 'formInErrorState']),
    ...mapState('features', ['selectedFeature', 'featuresAtFocusPoint', 'surveyEnabledSelection']),
    ...mapState('config', ['viewAttributes', 'readOnlyMode', 'readOnlyMessage']),
    ...mapState('map', ['activeLocation']),
    parentFeature(): Feature {
      // chosenParentFeature becomes the parent when parentless commenting is enabled
      // and the user has chosen a feature from the parent select dropdown in the survey.
      return this.selectedFeature || this.chosenParentFeature;
    },
    filteredCommentParentPairs(): ParentChildPair[] {
      return this.commentParentPairs.filter(
        (p: ParentChildPair) => p.child.xVetro.parentVetroId === this.parentFeature.xVetro.vetroId,
      );
    },
    shouldShowForm(): boolean {
      if (!this.isFeatureSelectionEnabled) return true;
      if (!this.surveyEnabledSelection) return false;

      return this.selectedFeature
        ? this.commentableLayerIds.has(this.selectedFeature.xVetro?.layerId)
        : true;
    },
    sidebarContentBlocks(): ContentBlockConfig[] {
      return (this.getViewAttributes?.sidebarConfig?.contentBlocks || []).filter(
        (block: ContentBlockConfig) =>
          this.shouldShowForm || block.type === ContentBlockTypes.FeatureInfo,
      );
    },
    hideTermsCheckbox(): boolean {
      return this.getViewAttributes?.sidebarConfig?.hideTermsCheckbox || false;
    },
    submissionConfig(): SubmissionConfig | null {
      return this.getViewAttributes?.submissionConfig || null;
    },
    submissionRedirectUrl(): string | null {
      return this.getSubmissionRedirectUrl || null;
    },
    inputBlockConfigs(): InputBlockConfig[] {
      return this.sidebarContentBlocks.filter(isInputBlockConfig);
    },
    allFormComponentsValid(): boolean {
      return Object.values(this.getFormValidity).every(Boolean);
      // return !allFormComponentsValid && this.formInErrorState;
    },
    submissionEnabled(): boolean {
      // FormValidity is an object with only booleans as values. Check if any are false!
      // const allFormComponentsValid = Object.values(this.getFormValidity).every(Boolean);
      const isShowingErrors = !this.allFormComponentsValid && this.formInErrorState;
      return (
        !this.formDisabled &&
        !isShowingErrors &&
        (this.termsAreAccepted || this.hideTermsCheckbox) &&
        !this.submitting
      );
    },
    redirectConfig(): RedirectionConfig | undefined {
      return this.submissionConfig?.redirect;
    },
    skipSubmissionModal(): boolean {
      return this.submissionConfig?.redirect?.skipSubmissionModal ?? false;
    },
    intersectingPolygons(): Feature<Polygon>[] {
      const redirectPolygonIntersectionIdentifier: PolygonIntersectionIdentifier | undefined =
        this.redirectConfig?.polygonIntersectionMapping;

      if (redirectPolygonIntersectionIdentifier) {
        const polygons = getMatchingIntersectionRelationshipPolygons(
          redirectPolygonIntersectionIdentifier,
          this.polygonIntersectionRelationships,
        );
        return polygons;
      }
      return [];
    },
    formDisabled(): boolean {
      return this.readOnlyMode;
    },
    formDisabledReason(): string {
      return this.readOnlyMessage;
    },
    reasonSubmissionDisabled() {
      if (this.formDisabled) {
        return this.formDisabledReason;
      }
      if (!this.allFormComponentsValid) {
        return 'Please correct errors in form';
      }
      if (!this.termsAreAccepted) {
        return 'Please review the Terms of Service and Privacy Policy';
      }
      if (this.submitting) {
        return 'Currently submitting';
      }
      return 'Not Disabled';
    },
  },
  watch: {
    activeLocation(location: Position) {
      if (location) this.chosenParentFeature = null;
    },
  },
  methods: {
    ...mapActions('sidebar', ['setSubmissionRedirectUrl', 'setFormErrorState', 'setSurveyActive']),
    ...mapActions('config', ['resetState']),
    ...mapActions('features', ['resetFeatureState']),
    ...mapActions('comments', ['fetchCommentsInPolygon']),
    ...mapActions('map', ['resetActiveLocation']),
    /**
     * In this function, we do the following if configured:
     *
     * - attach SL or Customer feature properties as query params to the redirect URL
     * - select a different redirect URL based on zone status, falling
     *   back to the baseRedirectUrl if none are configured for the
     *   user's current zone status(es)
     *
     * (Though Shared Views now supports more parents/children than just serviceLocation
     * and customer, custom ZM manager redirects currently rely on those naming
     * conventions.)
     */
    buildSubmissionRedirectUrl(serviceLocationAndCustomer: {
      serviceLocation: Feature | null;
      customer: Feature;
    }) {
      let url;

      if (
        this.redirectConfig?.polygonIntersectionMapping &&
        this.intersectingPolygons?.length &&
        this.intersectingPolygons.length > 0
      ) {
        url = mapPolygonsToRedirectURL(this.intersectingPolygons, this.redirectConfig);
      } else if (this.redirectConfig?.url) {
        url = this.redirectConfig.url;
      } else {
        url = '';
      }

      /**
       * Here we attach data from the Service Location or Customer features,
       * if the redirect URL contains placeholder(s) like:
       * https://zm-customer-site.com/search?customer={serviceLocation.xVetro.vetroId}
       */
      const formattedUrl = format(url, serviceLocationAndCustomer);
      this.setSubmissionRedirectUrl(formattedUrl);
    },
    async submit() {
      const allFormComponentsValid = Object.values(this.getFormValidity).every(Boolean);
      if (!allFormComponentsValid) {
        this.setFormErrorState(true);
        failureAlert(this.$store, 'Please review your submission and try again.');
        return;
      }
      this.submitting = true;
      try {
        const { submissionConfig, typeaheadAddressAttribute: addressAttribute = 'Address' } =
          this.viewAttributes;

        if (!submissionConfig) {
          // eslint-disable-next-line no-console
          console.error('No submission_config is configured on the view.');
        }
        const childCreationPayload = {
          properties: _pickBy(this.surveyData, (value) => !!value),
          submissionConfig,
          addressAttribute,
        };

        let parent: Feature | null;
        let child: Feature;

        const shouldUpsert =
          !this.chosenParentFeature &&
          this.selectedFeature &&
          !this.selectedFeature?.xVetro?.vetroId;

        if (shouldUpsert) {
          [parent, child] = await upsertParentWithChild({
            ...childCreationPayload,
            parent: this.selectedFeature,
          });
        } else {
          parent = this.selectedFeature || this.chosenParentFeature;

          const childGeometry = parent
            ? turfCentroid(parent as unknown as AllGeoJSON).geometry
            : pointFromPosition(this.activeLocation);

          child = await createChildFeature({
            ...childCreationPayload,
            parent,
            geometry: this.isFeatureSelectionEnabled ? childGeometry : null,
          });
        }

        this.buildSubmissionRedirectUrl({ serviceLocation: parent, customer: child });

        if (this.skipSubmissionModal) {
          if (this.submissionRedirectUrl) {
            window.location.href = this.submissionRedirectUrl;
          } else {
            this.resetState();
          }
        } else {
          this.$bvModal.show('submission-modal');
        }
      } catch (error) {
        failureAlert(this.$store, 'Survey submission failed.');
      }
      this.submitting = false;
    },
    closeForm() {
      this.setSurveyActive(false);
      this.resetActiveLocation();
      this.resetFeatureState();
    },
  },
});
