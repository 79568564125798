


























































































import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import {
  Layer,
  Category,
  isLayer,
  isCategorizedSymbol,
  CategorizedSymbolWithLayerInfo,
  LayerSymbol,
} from '@/types';
import VetroIcon from '@/components/util/VetroIcon.vue';
import InfoIcon from '@/assets/icons/info.svg?inline';
import { getOrderedCategorizedSymbols } from '@/util/layers';
import CollapseToggleButton from '@/components/util/CollapseToggleButton.vue';
import { MAX_CATEGORIES } from '@/constants';
import { getCategorizedAttributeLabel } from 'vetro-mapbox';

const CATEGORY_ROW_ID_PREFIX = 'category-';

enum TableItemTypes {
  CATEGORY = 'CATEGORY',
  LAYER = 'LAYER',
  CATEGORIZED_STYLE = 'CATEGORIZED_STYLE',
}

interface TableItem {
  id: string;
  type: TableItemTypes;
  label: string;
  geomType?: string | null;
  symbols?: Record<string, LayerSymbol>;
  description?: string | null;
}

interface CategorizedAttributeTableItem extends TableItem {
  layerId: number;
  attributeValue: string | number;
}

interface LayerListData {
  filter: { expandedIds: string[] };
  rowLabelTruncationMap: Record<string, boolean>;
}

export default Vue.extend({
  name: 'generic-parent-child-selection-table',
  components: {
    InfoIcon,
    VetroIcon,
    CollapseToggleButton,
  },
  props: {
    sortedCategoriesWithLayers: {
      type: Array as PropType<{ category: Category; layers: Layer[] }[]>,
      required: true,
    },
    layersByCategoryId: {
      type: Object as PropType<Record<string, { category: Category; layers: Layer[] }>>,
      required: true,
    },
    layerStatusMap: {
      type: Object as PropType<Record<number, boolean>>,
      required: true,
    },
    layerById: {
      type: Function as PropType<(layerId: number) => Layer>,
      required: true,
    },
    allLayersActiveForCategory: {
      type: Function as PropType<(categoryId: number) => boolean>,
      required: true,
    },
    categorizedAttributeStatusMap: {
      type: Object as PropType<Record<number, Record<string, boolean>>>,
      required: true,
    },
    shouldDisplayCategories: {
      type: Boolean,
      required: true,
    },
  },

  data(): LayerListData {
    return {
      filter: {
        expandedIds: Object.values(this.layersByCategoryId)
          .filter(({ layers }) => layers.length > 0)
          .map((arr) => `${CATEGORY_ROW_ID_PREFIX}${arr.category.id}`),
      },
      rowLabelTruncationMap: {},
    };
  },

  computed: {
    ...mapGetters('config', ['showDescriptions']),
    tableItems(): TableItem[] {
      return this.sortedCategoriesWithLayers
        .filter(({ layers }) => layers.length > 0)
        .flatMap(({ category, layers }) => {
          const layersWithCategorizedSymbols = layers.flatMap((layer) => {
            if (layer.style.categorizedAttributeLabel) {
              const categorizedSymbols = getOrderedCategorizedSymbols(layer);
              if (
                layer.style.hideCategorizedAttributes ||
                categorizedSymbols.length > MAX_CATEGORIES
              ) {
                return [layer];
              }

              // Remove symbols from the categorized layer because the following items will contain the symbols
              return [
                { ...layer, style: { ...layer.style, symbols: undefined } } as Layer,
                ...categorizedSymbols,
              ];
            }
            return layer;
          });

          if (!this.shouldDisplayCategories) return layersWithCategorizedSymbols;

          return this.filter.expandedIds.includes(`${CATEGORY_ROW_ID_PREFIX}${category.id}`)
            ? [category, ...layersWithCategorizedSymbols]
            : [category];
        })
        .map(this.buildTableItem);
    },
    tableFields(): Record<string, string>[] {
      return [{ key: 'id' }, { key: 'label' }, { key: 'info-icon' }];
    },
  },
  mounted() {
    this.setRowLabelTruncationMap();
    window.addEventListener('resize', this.setRowLabelTruncationMap);
  },
  methods: {
    minWidthColumns(key: string) {
      if (key !== 'info-icon') {
        return key === 'id' ? '20px' : '200px';
      }
      return '10px';
    },
    getCategoryIdFromRowId(rowId: string): number {
      return Number(rowId.replace(CATEGORY_ROW_ID_PREFIX, ''));
    },
    getLayersForCategoryRow(rowId: string): Layer[] {
      const categoryId = this.getCategoryIdFromRowId(rowId);
      return this.layersByCategoryId[categoryId].layers;
    },
    allLayersSelectedForCategory(rowId: string): boolean {
      const categoryId = this.getCategoryIdFromRowId(rowId);

      return this.allLayersActiveForCategory(categoryId);
    },
    someLayersSelectedForCategory(rowId: string): boolean {
      // We don't want *some* layers being selected to cause a category
      // checkbox's indeterminate state take precedence over its checked
      // (all selected) state.
      if (this.allLayersSelectedForCategory(rowId)) return false;

      return this.getLayersForCategoryRow(rowId).some(
        ({ id: layerId }) => this.layerStatusMap[layerId],
      );
    },
    allCategorizedAttributesActiveForLayer(rowId: string): boolean {
      const layerId = Number(rowId);
      if (this.categorizedAttributeStatusMap[layerId]) {
        return Object.values(this.categorizedAttributeStatusMap[layerId]).every((value) => value);
      }
      return this.layerStatusMap[layerId];
    },
    someCategorizedAttributesSelectedForLayer(rowId: string): boolean {
      const layerId = Number(rowId);
      // We don't want *some* attributes being selected to cause a layer
      // checkbox's indeterminate state take precedence over its checked
      // (all selected) state.
      if (this.allCategorizedAttributesActiveForLayer(rowId)) return false;

      return (
        this.layerStatusMap[layerId] &&
        this.categorizedAttributeStatusMap[layerId] &&
        Object.values(this.categorizedAttributeStatusMap[layerId]).some((value) => value)
      );
    },
    categorizedAttributeIsChecked(item: CategorizedAttributeTableItem): boolean {
      const { layerId, attributeValue } = item;
      return (
        this.categorizedAttributeStatusMap[layerId] &&
        this.categorizedAttributeStatusMap[layerId][attributeValue]
      );
    },
    toggleCategorizedAttribute(item: CategorizedAttributeTableItem): void {
      const { layerId, attributeValue } = item;
      this.$emit('toggle-categorized-attribute', { layerId, attributeValue });
    },
    toggleLayer(item: TableItem): void {
      this.$emit('toggle-layer', Number(item.id));
    },
    toggleCategory(rowId: string): void {
      this.$emit('toggle-category', this.getCategoryIdFromRowId(rowId));
    },
    handleRowToggle(itemId: string): void {
      if (this.filter.expandedIds.includes(itemId)) {
        this.filter.expandedIds = this.filter.expandedIds.filter((id) => id !== itemId);
      } else {
        this.filter.expandedIds = [...this.filter.expandedIds, itemId];
      }
    },
    isLayerTableItem(item: TableItem) {
      return item.type === TableItemTypes.LAYER;
    },
    isCategoryTableItem(item: TableItem) {
      return item.type === TableItemTypes.CATEGORY;
    },
    isCategorizedSymbolTableItem(item: TableItem) {
      return item.type === TableItemTypes.CATEGORIZED_STYLE;
    },
    buildLayerTableItem(layer: Layer): TableItem {
      const { id, label, geomType, style, description } = layer;

      return {
        id: `${id}`,
        type: TableItemTypes.LAYER,
        label,
        geomType,
        symbols: style.symbols,
        description,
      };
    },
    buildCategoryTableItem(category: Category): TableItem {
      const { id, label, description } = category;
      return {
        id: `${CATEGORY_ROW_ID_PREFIX}${id}`,
        type: TableItemTypes.CATEGORY,
        label,
        description,
      };
    },
    buildCategorizedSymbolTableItem(
      categorizedSymbol: CategorizedSymbolWithLayerInfo,
    ): CategorizedAttributeTableItem {
      const { attributeValue, layerId, symbol, geomType, description } = categorizedSymbol;

      return {
        id: `${layerId}-${attributeValue}`, // This format is relied on toggleCategorizedAttribute
        layerId,
        attributeValue,
        type: TableItemTypes.CATEGORIZED_STYLE,
        label: getCategorizedAttributeLabel(this.layerById(layerId), attributeValue),
        geomType,
        symbols: { default: symbol },
        description,
      };
    },
    buildTableItem(item: Layer | Category | CategorizedSymbolWithLayerInfo): TableItem {
      if (isCategorizedSymbol(item)) return this.buildCategorizedSymbolTableItem(item);

      return isLayer(item) ? this.buildLayerTableItem(item) : this.buildCategoryTableItem(item);
    },
    // We only want to enable a toolip for a label *if* it is truncated. But we don't
    // know if a label will be truncated until it is rendered. So we compute the relationship
    // between a row label and whether or not it is truncated on mount, or any time the table's
    // filter is updated (a category is expanded).
    setRowLabelTruncationMap() {
      this.$nextTick(() => {
        this.rowLabelTruncationMap = this.tableItems.reduce(
          (acc: Record<string, boolean>, { id }) => {
            const labelElement = document.getElementById(`row-label-${id}`);
            acc[id] = labelElement
              ? labelElement.offsetWidth >= 180 || // 180 is the max width of the label element
                labelElement.offsetWidth < labelElement.scrollWidth
              : false;
            return acc;
          },
          {},
        );
      });
    },
  },
});
