








import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import uuidValidator from 'uuid-validate';
import ImageCarousel from './ImageCarousel.vue';
import PopoutCarousel from './PopoutCarousel.vue';

export default Vue.extend({
  components: {
    ImageCarousel,
    PopoutCarousel,
  },
  props: {
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  data() {
    return {
      currentSlideIndex: 0,
    };
  },
  computed: {
    ...mapState('features', ['imageAttributeDomain']),
    imageKeys(): string[] {
      return this.value ?? [];
    },
    imagePaths(): string[] {
      if (!this.imageAttributeDomain) return [];
      return this.imageKeys.map((key) => this.keyToUrl(key));
    },
  },
  methods: {
    popoutCarousel() {
      this.$bvModal.show('carousel-popout');
    },
    keyToUrl(key: string) {
      return uuidValidator(key) ? `https://${this.imageAttributeDomain}/${key}` : key;
    },
  },
});
