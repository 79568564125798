




















































import { mapState, mapGetters, mapActions } from 'vuex';
import Vue from 'vue';
import {
  VetroMap,
  getCenter,
  isPoint,
  addBufferToBounds,
  VetroFeatureLayer,
  getCategorizedFilterExpression,
} from 'vetro-mapbox';
import turfBBox from '@turf/bbox';
import { Map, CameraOptions } from 'mapbox-gl';
import { MglMarker } from 'v-mapbox';
import _debounce from 'lodash.debounce';
import bboxPolygon from '@turf/bbox-polygon';
import { Layer } from '@/types/layers';
import { DEFAULT_JUMP_ZOOM, MapboxBasemap, MAP_ACTION_TYPE } from '@/constants';
import { Feature, BBox, MapAction } from '@/types';
import MapMarkerIcon from '@/assets/icons/map-marker.svg?inline';
import { getMapSidebarPadding } from '@/util/map';
import { updateUrlMapLocation } from '@/util/urlAttributesParser';
import FeatureSelectionManager from './map/FeatureSelection/FeatureSelectionManager.vue';
import CommentClusters from './map/CommentClusters.vue';

const AVAILABLE_MAPBOX_BASEMAPS = Object.values(MapboxBasemap);

export default Vue.extend({
  name: 'map-container',
  components: {
    VetroMap,
    VetroFeatureLayer,
    MglMarker,
    MapMarkerIcon,
    FeatureSelectionManager,
    CommentClusters,
  },
  data() {
    return {
      mapHasLoaded: false,
    };
  },
  created() {
    this.handleCommentFetch = _debounce(this.handleCommentFetch, 200);
  },
  computed: {
    ...mapGetters('layers', ['mapboxToVetroLayerIdMap', 'attributeStatusMapByLayerId']),
    ...mapGetters('config', ['getViewAttributes', 'isFeatureSelectionEnabled']),
    ...mapState('config', ['planIds', 'tileServers', 'publicApiToken', 'viewAttributes']),
    ...mapState('map', ['mapPositionResetFlag', 'activeLocation', 'mapAction']),
    ...mapState('features', ['selectedFeature', 'shouldDisplayAndHighlightSelectedFeature']),
    ...mapState('sidebar', ['sidebarIsActive']),
    ...mapGetters('layers', ['sortedLayers', 'activeLayerIds', 'layerIdGeomTypeMap']),
    ...mapGetters('config', ['canViewSubmittedComments']),
    ...mapState('comments', ['showCommentsOnMap']),

    selectedFeatureId(): string {
      return this.selectedFeature?.xVetro?.vetroId;
    },
    availableBasemaps() {
      const mapboxBasemaps = AVAILABLE_MAPBOX_BASEMAPS.map((name) => ({ name, disabled: false }));
      return [...mapboxBasemaps];
    },
    mapAccessToken(): string {
      return process.env.VUE_APP_MAPBOX_TOKEN;
    },
    mapLayers(): Layer & { visible: boolean } {
      return this.sortedLayers.map((layer: Layer) => ({
        ...layer,
        visible: this.activeLayerIds.includes(layer.id),
      }));
    },
    initialBasemap(): MapboxBasemap {
      return this.getViewAttributes?.initialBasemap || AVAILABLE_MAPBOX_BASEMAPS[0];
    },
    labelLayerMinZoom(): number {
      return this.viewAttributes?.labelLayerMinZoom || 13;
    },
    maxBounds(): [number, number][] | undefined {
      const maxBounds = this.getViewAttributes?.maxBounds;
      if (!maxBounds) return undefined;
      let southwestLng = maxBounds.southwest?.lng || -180;
      let southwestLat = maxBounds.southwest?.lat || -90;
      let northeastLng = maxBounds.northeast?.lng || 180;
      let northeastLat = maxBounds.northeast?.lat || 90;
      if (southwestLng > northeastLng) {
        [southwestLng, northeastLng] = [northeastLng, southwestLng];
      }
      if (southwestLat > northeastLat) {
        [southwestLat, northeastLat] = [northeastLat, southwestLat];
      }
      return [
        [southwestLng, southwestLat],
        [northeastLng, northeastLat],
      ];
    },
    defaultCenter(): [number, number] | undefined {
      const initialMapCenter = this.viewAttributes?.initialMapCenter;
      return initialMapCenter
        ? (Object.values(initialMapCenter).reverse() as [number, number])
        : undefined;
    },
    defaultZoom(): number | undefined {
      return this.getViewAttributes?.initialMapZoom;
    },
    shouldDisplayMarker(): boolean {
      return this.activeLocation?.length > 0;
    },
    allowFeatureSelection(): boolean {
      return this.isFeatureSelectionEnabled && this.mapHasLoaded;
    },
    highlightedFeature(): Feature {
      return this.shouldDisplayAndHighlightSelectedFeature ? this.selectedFeature : null;
    },
  },
  watch: {
    mapPositionResetFlag(flag): void {
      if (flag) {
        this.jump({ center: this.defaultCenter, zoom: this.defaultZoom });
        this.unsetMapPositionResetFlag();
      }
    },
    sidebarIsActive(): void {
      // Just adjusts the map padding compensation when the sidebar is toggled
      this.jump();
    },
    selectedFeature(): void {
      if (this.canViewSubmittedComments && !this.selectedFeature) {
        this.handleCommentFetch();
      }
      if (this.shouldDisplayAndHighlightSelectedFeature && this.selectedFeature) {
        this.goToFeature(this.selectedFeature);
      }
    },
    mapAction(action: MapAction) {
      const { type, watcherHitCallback = () => {} } = action;

      if (type === MAP_ACTION_TYPE.JUMP) {
        const { coordinates } = action.payload;
        this.jump({ center: coordinates, zoom: DEFAULT_JUMP_ZOOM });
      }

      watcherHitCallback();
    },
  },
  methods: {
    ...mapActions('map', ['unsetMapPositionResetFlag', 'setMapPositionResetFlag']),
    ...mapActions('comments', ['fetchCommentsInPolygon', 'setShowCommentsOnMap']),
    getMapboxId(layer: Layer): string | null {
      const layerMap = Object.entries(this.mapboxToVetroLayerIdMap).find(
        ([__, layerId]) => layerId === layer.id,
      );
      if (layerMap) {
        return layerMap[0];
      }

      return null;
    },
    planLayerAttributeFilter(layer: Layer): Array<unknown> {
      if (this.planIds.length < 1) return [];

      const filter: Array<unknown> = ['all', ['in', ['get', 'plan_id'], ['literal', this.planIds]]];

      const attributeStatusMap = this.attributeStatusMapByLayerId(layer.id);

      const { categorizedAttributeLabel } = layer.style;

      if (categorizedAttributeLabel) {
        filter.push(getCategorizedFilterExpression(layer, attributeStatusMap));
      }

      return filter;
    },
    onMapLoaded(map: Map): void {
      this.map = map;
      this.mapHasLoaded = true;
      // Compensate for the sidebar width in the default map center.
      this.setMapPositionResetFlag();

      this.map.on('moveend', () => {
        if (this.canViewSubmittedComments) {
          this.handleCommentFetch();
        }
        if (this.viewAttributes.keepUrlUpdated) {
          updateUrlMapLocation(this.map);
        }
      });

      // Add jump method to window for use with UserFlows
      window.mapJumpTo = this.jump;

      window.map = map;
    },
    jump(options: CameraOptions = {}) {
      if (this.map) {
        this.map.jumpTo({
          ...options,
          padding: getMapSidebarPadding(this.sidebarIsActive),
        });
      }
    },
    goToFeature(feature: Feature) {
      const { geometry } = feature;

      if (!geometry || !this.map) return;

      if (isPoint(geometry.type)) {
        const center = getCenter(geometry.coordinates, geometry.type);
        const zoom = this.map.getZoom() < 16 ? 16 : this.map.getZoom();

        this.jump({ center, zoom });
      } else {
        // turf bboxes can be 2D or 3D. fitBounds below only accepts 2D
        const bbox = turfBBox(geometry) as BBox;
        const bufferedBbox = addBufferToBounds(bbox);

        this.map.fitBounds(bufferedBbox, {
          linear: true,
        });
      }
    },
    handleCommentFetch() {
      if (this.map && !this.selectedFeature) {
        const bbox = this.map.getBounds().toArray().flat() as BBox;
        const { geometry } = bboxPolygon(bbox);
        this.fetchCommentsInPolygon(geometry);
      }
    },
    onMapStyleChanged() {
      this.setShowCommentsOnMap(false);
      this.$nextTick(() => {
        this.setShowCommentsOnMap(true);
      });
    },
  },
});
