














































import Vue, { PropType } from 'vue';
import _debounce from 'lodash.debounce';
import type { AddressPrediction } from '@/types';
import SearchIcon from '@/assets/icons/icon-search.svg?inline';
import ClearIcon from '@/assets/icons/clear.svg?inline';

type SuggestedSearchResult = AddressPrediction & { id: number };

interface PureSearchData {
  searchTerm: string;
  searchBarText: string;
  highlightedItemId: number | null;
}

export default Vue.extend({
  name: 'pure-search',
  components: {
    SearchIcon,
    ClearIcon,
  },
  props: {
    addressPredictions: { type: Array as PropType<AddressPrediction[] | null>, required: false },
    selectedAddress: { type: Object as PropType<AddressPrediction | null>, required: false },
    searchPlaceholder: { type: String, required: false, default: 'Search By Address' },
  },
  data(): PureSearchData {
    return {
      searchTerm: '',
      searchBarText: this.selectedAddress?.address || '',
      highlightedItemId: null,
    };
  },
  created() {
    this.handleAddressInput = _debounce(this.handleAddressInput, 400);
  },
  watch: {
    selectedAddress(newAddress) {
      if (!newAddress) {
        this.searchBarText = '';
      }
    },
  },
  computed: {
    noSuggestionsReturned(): boolean {
      // An empty list means we asked and the API gave us no suggestions,
      //  while null means we haven't asked for suggestions yet
      return Boolean(this.addressPredictions && this.addressPredictions.length === 0);
    },
    suggestions() {
      return this.addressPredictions
        ? [
            {
              data: this.addressPredictions?.map((prediction, index) => ({
                ...prediction,
                id: index, // Add id's for highlighting
              })),
            },
          ]
        : [];
    },
    inputProps(): Record<string, unknown> {
      return {
        placeholder: this.searchPlaceholder,
        spellcheck: 'false',
        'aria-label': 'Address Search Input',
      };
    },
    inputRef() {
      const autoSuggestRef = this.$refs?.autosuggest as Vue | undefined;
      return autoSuggestRef ? autoSuggestRef.$el.querySelector('input') : null;
    },
  },
  methods: {
    handleAddressInput(address: string) {
      if ((address || "").replaceAll(" ", "").length > 4) {
        this.searchTerm = address;
        this.$emit('match-address', address);
      }
    },
    handleBlur() {
      /* This needs to be a timeout because the blur event fires when you select using enter key
       *  1. selectAddress sets searchBarText to new value
       *  2. This listener sets searchBarText to the value its receiving as prop (old value)
       *  3. The prop is updated to new value (but not searchBarText)
       */

      this.$nextTick(() => {
        if (this.selectedAddress) {
          this.searchBarText = this.selectedAddress.address;
        } else {
          this.searchBarText = '';
        }
      });
    },
    handleItemChanged(suggestion: { item: SuggestedSearchResult }) {
      if (suggestion) this.highlightedItemId = suggestion.item.id;
    },
    handleSelectAddress(prediction?: { item: AddressPrediction }) {
      if (!prediction) return;
      this.selectAddress(prediction.item);
    },
    handleEnter() {
      if (!this.addressPredictions?.length) {
        if (this.selectedAddress) {
          // User is hitting enter on the address bar to reset their view
          //  on their currently-selected address
          this.selectAddress(this.selectedAddress);
        }
        return;
      }

      if (this.highlightedItemId) {
        this.selectAddress(this.addressPredictions.find((a) => a.id === this.highlightedItemId));
      } else {
        this.selectAddress(this.addressPredictions[0]);
      }
    },
    selectAddress(address?: AddressPrediction) {
      if (!address) return;

      this.highlightedItemId = null;
      this.searchBarText = address.address;

      this.$emit('select-address', address);
      if (this.inputRef) {
        this.inputRef.blur();
      }
    },
    shouldShowSuggestions(): boolean {
      return this.searchTerm.length > 2 && document.activeElement === this.inputRef;
    },
    getSuggestionValue(suggestion: { item: AddressPrediction }) {
      return suggestion.item.address;
    },
  },
});
