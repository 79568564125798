














































































import Vue, { PropType } from 'vue';
import { mapState, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import _sortBy from 'lodash.sortby';
import _isEqual from 'lodash.isequal';
import { Feature, Layer, LayerSymbol } from '@/types';
import { getFeatureDefaultSymbolMap } from '@/util/features';
import VetroIcon from '@/components/util/VetroIcon.vue';
import InfoIcon from '@/assets/icons/info.svg?inline';
import ImageAttributeCore from './attribute-formatting/images/ImageAttributeCore.vue';
import DisplayLinks from './attribute-formatting/Links.vue';

enum SpecialAttributeFormats {
  Currency = 'currency',
  Date = 'date',
  Images = 'images',
  Links = 'links',
  Number = 'number',
}

export default Vue.extend({
  name: 'feature-info-block',
  components: {
    VetroIcon,
    ImageAttributeCore,
    DisplayLinks,
    InfoIcon,
  },
  props: {
    parentFeature: { type: Object as PropType<Feature>, required: false },
  },
  data() {
    return {
      SpecialAttributeFormats,
    };
  },
  computed: {
    ...mapState('features', ['selectedFeature', 'shouldDisplayAndHighlightSelectedFeature']),
    ...mapGetters('layers', ['layerById']),
    ...mapGetters('config', ['showDescriptions']),
    featureLayer(): Layer {
      return this.layerById(this.parentFeature.xVetro.layerId);
    },
    featureSymbols(): Record<string, LayerSymbol> {
      return getFeatureDefaultSymbolMap(this.parentFeature, this.featureLayer);
    },
    featureAttributes(): Record<string, unknown>[] {
      const attrs = Object.entries(this.parentFeature.properties).map(([label, value]) => ({
        label,
        value,
        ...this.getAttributeFormat(label),
        displayOrder: this.getDisplayOrder(label),
        isHidden: this.getIsHidden(label),
      }));

      return _sortBy(attrs, 'displayOrder').filter((attr) => !attr.isHidden);
    },
    shouldShowBlock(): boolean {
      if (!this.parentFeature) return false;

      return _isEqual(this.parentFeature, this.selectedFeature)
        ? this.shouldDisplayAndHighlightSelectedFeature
        : true;
    },
  },
  methods: {
    layerAttributeDescription(attributeLabel: string): string | null {
      return this.featureLayer.availableAttributes[attributeLabel].description;
    },
    getAttributeFormat(attribute: string): Record<string, string | null> {
      return {
        format: this.featureLayer.availableAttributes[attribute].displayFormat,
        type: this.featureLayer.availableAttributes[attribute].htmlInputType,
      };
    },
    getDisplayOrder(attribute: string) {
      return this.featureLayer.availableAttributes[attribute].displayOrder;
    },
    getIsHidden(attribute: string) {
      return this.featureLayer.availableAttributes[attribute].isHidden;
    },
    formatDate(value: string) {
      return value ? dayjs(value).format('M/D/YYYY') : '';
    },
    formatCurrency(value: number) {
      return typeof value === 'number'
        ? value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        : '';
    },
    formatNumber(value: number) {
      return typeof value === 'number' ? value.toLocaleString('en-US') : '';
    },
  },
});
