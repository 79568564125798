
















import Vue, { PropType } from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import booleanIntersects from '@turf/boolean-intersects';
import _isEqual from 'lodash.isequal';
import _uniq from 'lodash.uniq';
import { Map, LngLat, Point } from 'mapbox-gl';
import { Position, Geometry } from '@turf/helpers';
import { Feature } from '@/types';
import { MglPopup } from 'v-mapbox';
import { getClusterPointsAtPoint, zoomToFeatures } from '@/util/clustering';
import { getFeaturesWithinPointRadius } from '@/util/features';
import { pointFromPosition } from '@/util/geom';
import { DEFAULT_JUMP_ZOOM } from '@/constants';
import FeatureDisambiguationMenu from './FeatureDisambiguationMenu.vue';

interface ClickEvent {
  lngLat: LngLat;
  point: Point;
}

interface FeatureSelectionManagerData {
  lastClickLngLat: LngLat | null;
  mapClickCoordinates: Position | null;
  showPopup: boolean;
  featuresAtClick: Feature[];
}

export default Vue.extend({
  name: 'feature-selection-manager',
  components: {
    MglPopup,
    FeatureDisambiguationMenu,
  },
  props: {
    map: { type: Object as PropType<Map>, required: true },
    layerIdGeomTypeMap: { type: Object as PropType<Record<number, string>>, required: true },
  },
  data(): FeatureSelectionManagerData {
    return {
      lastClickLngLat: null,
      mapClickCoordinates: null,
      showPopup: false,
      featuresAtClick: [],
    };
  },
  mounted() {
    this.map.on('mouseup', this.onMouseUp);
    this.map.on('mousedown', this.onMouseDown);
  },
  destroyed() {
    this.map.off('mouseup', this.onMouseUp);
    this.map.off('mousedown', this.onMouseDown);
  },
  computed: {
    ...mapState('config', ['planIds', 'viewAttributes']),
    ...mapState('comments', ['showCommentsOnMap']),
    ...mapGetters('layers', ['activeLayerIds']),
    ...mapGetters('config', [
      'parentlessCommentsEnabled',
      'commentableLayerIds',
      'allowCommentOnSelectedFeature',
    ]),
    featureOptions(): Feature[] {
      return this.featuresAtClick.filter((feature) =>
        this.activeLayerIds.includes(feature.xVetro.layerId),
      );
    },
  },
  methods: {
    ...mapActions('features', ['setSelectedFeature', 'setFeaturesAtFocusPoint']),
    ...mapActions('sidebar', ['setSurveyActive']),
    ...mapActions('map', ['setActiveLocation', 'resetActiveLocation']),
    onMouseDown(event: ClickEvent) {
      this.lastClickLngLat = event.lngLat;
    },
    onMouseUp(event: ClickEvent) {
      if (_isEqual(this.lastClickLngLat, event.lngLat)) {
        this.handleMapClick(event);
      }
    },
    async handleMapClick(event: ClickEvent) {
      this.mapClickCoordinates = [event.lngLat.lng, event.lngLat.lat];

      if (this.viewAttributes.selectionBounds) {
        const isInBounds = booleanIntersects(
          this.viewAttributes.selectionBounds,
          pointFromPosition(this.mapClickCoordinates),
        );
        if (!isInBounds) {
          return;
        }
      }

      if (this.showCommentsOnMap) {
        const { features: clusterFeatures, isCluster } = await getClusterPointsAtPoint(
          this.map,
          event.point,
          { includeUnclustered: true },
        );

        // If the user clicked on a cluster, we'll zoom to the the bounding box of the cluster
        // except for the case that the cluster is all in one polygon, in which case we'll treat it as a normal click.
        const numUniqueFeatures = new Set(clusterFeatures.map((f) => JSON.stringify(f.geometry)))
          .size;

        if (isCluster && numUniqueFeatures > 1) {
          zoomToFeatures(this.map, clusterFeatures);
          return;
        }
        if (numUniqueFeatures > 0) {
          const geometry = clusterFeatures[0].geometry as Geometry;
          const [lng, lat] = geometry.coordinates;

          this.map.flyTo({ center: { lng, lat } as LngLat, zoom: DEFAULT_JUMP_ZOOM, speed: 5 });
          return;
        }
      }

      if (this.parentlessCommentsEnabled) {
        await this.handleFeatureClickLaxCommentParent(event);
      } else {
        await this.handleFeatureClickStrictCommentParent(event);
      }
    },
    async fetchFeaturesAtClick(event: ClickEvent, layerIds: number[]) {
      this.featuresAtClick = await getFeaturesWithinPointRadius({
        point: [event.point.x, event.point.y],
        layerIds,
        planIds: this.planIds,
      });
    },
    async handleFeatureClickLaxCommentParent(event: ClickEvent) {
      await this.fetchFeaturesAtClick(
        event,
        _uniq([...this.activeLayerIds, ...this.commentableLayerIds]),
      );

      if (this.featureOptions.length < 1) {
        this.handleAddComment();
      } else {
        this.showPopup = true;
      }
    },
    async handleFeatureClickStrictCommentParent(event: ClickEvent) {
      await this.fetchFeaturesAtClick(event, this.activeLayerIds);

      if (this.featureOptions.length === 1) {
        this.handleFeatureSelection(this.featuresAtClick[0]);
      } else if (this.featureOptions.length > 1) {
        this.showPopup = true;
      }
    },
    handleFeatureSelection(feature: Feature) {
      this.setFeaturesAtFocusPoint(this.featuresAtClick);
      this.setSelectedFeature({
        feature,
        surveyEnabledSelection: this.allowCommentOnSelectedFeature,
      });
      this.resetActiveLocation();
      this.$emit('on-feature-select', feature);
      this.showPopup = false;
    },
    handleAddComment() {
      // In the feature disambiguation menu, we display features intersecting with some radius
      // around the exact clicked point. If the user chooses to add a comment without selecting
      // a feature, we want to display in the survey's parent selection dropdown only those
      // features that exactly intersect with the clicked point.
      const features = this.featuresAtClick.filter((feature) =>
        feature.geometry && this.mapClickCoordinates
          ? booleanIntersects(feature.geometry, pointFromPosition(this.mapClickCoordinates))
          : false,
      );

      this.setFeaturesAtFocusPoint(features);

      this.setSelectedFeature({ feature: null, shouldDisplayAndHighlight: false });
      this.setActiveLocation({ coordinates: this.mapClickCoordinates, shouldJump: false });
      this.setSurveyActive(true);
      this.showPopup = false;
    },
  },
});
