
































































import Vue, { PropType } from 'vue';
import { paramCase } from 'change-case';
import { mapState, mapGetters } from 'vuex';
import { Feature, InputBlockConfig } from '@/types';
import VetroIcon from '@/components/util/VetroIcon.vue';
import FeatureLabel from '@/components/util/FeatureLabel.vue';
import { NO_PARENT_ICON } from '@/constants';

export default Vue.extend({
  name: 'parent-selection-input-block',
  components: {
    FeatureLabel,
    VetroIcon,
  },
  props: {
    config: { type: Object as PropType<InputBlockConfig>, required: true },
    parentFeature: { type: Object as PropType<Feature>, required: false },
    featuresAtFocusPoint: { type: Array as PropType<Feature[]>, required: false, default: [] },
    chosenParentFeature: { type: Object as PropType<Feature>, required: false },
    disabled: { type: Boolean, required: false, default: false },
    disabledReason: { type: String, required: false, default: '' },
  },
  data() {
    return {
      NO_PARENT_ICON,
      selectedParent: null,
    };
  },
  computed: {
    ...mapState('features', ['selectedFeature']),
    ...mapGetters('config', ['commentableLayerIds']),
    ...mapGetters('layers', ['activeLayerIds']),
    inputId(): string {
      return paramCase(this.config.type);
    },
    isDisabled(): boolean {
      return (
        this.disabled || this.selectedFeature !== null || this.commentableFeatures.length === 0
      );
    },
    commentableFeatures(): Feature[] {
      return this.featuresAtFocusPoint.filter((feature) =>
        this.commentableLayerIds.has(feature.xVetro.layerId),
      );
    },
    visibleCommentableFeatures(): Feature[] {
      return this.commentableFeatures.filter((feature) =>
        this.activeLayerIds.includes(feature.xVetro.layerId),
      );
    },
    hiddenCommentableFeatures(): Feature[] {
      return this.commentableFeatures.filter(
        (feature) => !this.activeLayerIds.includes(feature.xVetro.layerId),
      );
    },
  },
  methods: {
    selectParent(parent: Feature | null) {
      this.$emit('on-parent-select', parent);
    },
  },
});
